import React from "react"
import './Banner.scss'
const Banner = ({ title, description, image, background }) => {
  return (
    <div className="baner_box" style={{ backgroundImage: `url(${background})` }}>
      <div className="banner_content">
        <div className="banner_msg">
          <span className="banner_title">{title}</span>
          <span className="banner_description">{description}</span>
        </div>
        <div className="banner_img">
          <img src={image}/>
        </div>
      </div>
    </div>
  )
}

export default Banner
